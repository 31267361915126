import { Link } from "gatsby";
import React, { useContext } from "react";
import BreadCrumb from "../../components/layout/breadcrumb";
import Layout from "../../components/layout/layout";
import Seo from "../../components/layout/seo";
import { LanguageContext } from "../../context/language-context";
import t from "../../resources/translations";

const PrivacyPolicy = ({ location }) => {
  const l = useContext(LanguageContext).locale;
  return (
    <Layout location={location}>
      <Seo title="Privacy Policy" desc="" />
      <BreadCrumb>
        <Link to="/">Home</Link>
        <p className="font-bold">{t.privacyTitolo[l]}</p>
      </BreadCrumb>
      <div className="my-4 max-w-prose mx-auto">
        <h1 className="md:is-h1 is-h1m text-white mb-4">
          {t.privacyTitolo[l]}
        </h1>
        <h2>Premise</h2>
        <p>
          The access, consultation, and retrieval of information offered by
          International Saws are absolutely free. <br />
          International Saws reserves the right to modify the methods of use of
          this website at any time and without prior notice.
        </p>
        <br />
        <h3>Rights</h3>
        <p>
          The intellectual property of the International Saws website belongs
          exclusively to International Saws srl. <br />
          All rights inherent to it are reserved. The name, trademark and any
          other distinctive sign belonging to International Saws, as well as all
          images and texts published on this website, cannot be duplicated,
          reproduced and / or disseminated in any way and by any means without
          authorization. written. <br />
          All trademarks, product names and / or company names mentioned and /
          or represented on this website belong exclusively to their respective
          owners.
        </p>
        <br />
        <h3>Information</h3>
        <p>
          International Saws is not responsible for any errors, omissions and /
          or inaccuracies in the information contained in this website and
          reserves the right to make any changes and / or rectifications thereof
          without any warning of any kind. <br />
          International Saws is free to publish all information of any kind and
          form, including articles and reviews, provided by users or third
          parties, except in the case in which the authors or suppliers
          themselves expressly prohibit its dissemination.
          <br />
          International Saws is not responsible for any copyright infringement
          due to third parties carried out by the author of any computer program
          published on this site. International Saws undertakes to immediately
          remove from its electronic archives the program created in violation
          of copyright laws, as soon as it becomes aware of the violation
          itself.
        </p>
        <br />
        <h3>Usage</h3>
        <p>
          You expressly agree to use the International Saws site at your own
          risk. <br />
          International Saws does not give any guarantee on the correct
          functioning of this website and declines any responsibility deriving
          from the use of the same. <br />
          The user undertakes to use the International Saws website for legal
          purposes only. <br />
          The user is strictly forbidden to transfer to International Saws
          material that violates the rights of third parties and / or illegal,
          defamatory, offensive, obscene material, as well as any information of
          any kind that could in any way encourage criminal actions sanctioned
          by law and / or involve liability. civil.
          <br />
          Nevertheless, the user is prohibited from transferring material
          protected by copyright, registered trademark or any other form of
          property right to International Saws without the permission of the
          respective author and / or owner. <br />
          International Saws cannot be held responsible for the illegal use of
          copyrights and other rights belonging to third parties, made by the
          user. <br />
          Only the latter will be held responsible for damages deriving from the
          violation of copyrights and / or other property rights belonging to
          third parties.
          <br />
          With the direct or indirect transfer of material and / or information
          on International Saws the user grants, or guarantees that the owner of
          the material and / or information has granted, to International Saws
          the free, perpetual and non-exclusive right to publish , reproduce,
          translate and distribute said material and / or information in whole
          or in part. <br />
          International Saws undertakes to remove illegal or illegal material
          and / or information transferred to this website by users, as soon as
          it becomes aware of the illegal or illegal.
        </p>
        <br />
        <h3>Mail</h3>
        <p>
          By inserting material on our servers, for example by e-mail or through
          the pages of the site, the user undertakes to:
        </p>
        <br />
        <ul>
          <li>
            check that the material does not contain any data that is illegal or
            otherwise unsuitable for publication;
          </li>
          <li>
            check and remove any virus or any other contaminating or destructive
            element before introducing any material.
          </li>
        </ul>{" "}
        <br />
        <h3>Connections</h3>
        <p>
          The sites accessible through hypertext links on this website are not
          under the control of International Saws. <br />
          International Saws is not responsible for the contents of linked sites
          or links contained in a linked site or for changes or updates to such
          sites. <br />
          The inclusion of any link on this website in no way implies approval
          by International Saws of the linked site.
        </p>
        <br />
        <h3>Responsibility</h3>
        <p>
          Under no circumstances and for no reason can International Saws be
          held liable for any damage, whether direct, indirect, incidental,
          consequential, and in particular for damages relating to loss of data
          or profits and / or loss of use, deriving from or in some way
          attributable to the International Saws website and / or the
          information contained therein.
        </p>
        <br />
        <h1>Cookies</h1>
        <p>
          This document was created for the purpose of disclosing the procedures
          followed for the collection through cookies and / or other monitoring
          technologies of the information provided by users when they visit this
          website. <br />
          Visitors to the site will be able to disable the functionality of
          tracking cookies in their browser, but in some cases this may lead to
          malfunctions in the use of our website.
        </p>
        <br />
        <h2>General information about the rules</h2>
        <p>
          The art. 122 of Legislative Decree 196/2003 has transposed into the
          Italian legal system a European Directive which imposes on the
          managers of websites that use cookies or other monitoring
          technologies, the obligation to inform the user about the types of
          cookies used. from the site, and to request the prior consent of the
          same to send these cookies on the user's terminal device and / or to
          access the information recorded therein. <br />
          The only exceptions to the request for consent are cookies strictly
          necessary for the management of the site, or for the provision of
          services expressly requested by the user.
        </p>
        <br />
        <h2>General information on cookies</h2>
        <p>
          We use cookies in some areas of our site. Cookies are text files which
          store information on the user's hard drive or on the navigation
          browser, and allow you to check whether the user has already visited
          the website in the past, as well as the pages of the site most
          visited, as they record which pages have been visited and the time
          spent on them. <br />
          For changes in cookie preferences, given the diversity in the setting
          of the different browsers used for Internet access, it is advisable to
          click on the menu item "Help / Help" of the browser used to understand
          how to do it. Below is the list of links of the most used browsers, in
          which it is explained how to manage or disable cookies:
        </p>
        <br />
        <ul>
          <li>
            Internet Explorer:{" "}
            <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=http://windows.microsoft.com/it-IT/internet-explorer/delete-manage-cookies">
              http://windows.microsoft.com/it-IT/internet-explorer/delete-manage-cookies
            </a>{" "}
            ;
          </li>
          <li>
            Google Chrome:{" "}
            <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://support.google.com/chrome/answer/95647">
              https://support.google.com/chrome/answer/95647
            </a>{" "}
            ;
          </li>
          <li>
            Mozilla Firefox:{" "}
            <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=http://support.mozilla.org/it/kb/Gestione%2520dei%2520cookie">
              http://support.mozilla.org/it/kb/Gestione%20dei%20cookie
            </a>{" "}
            ;
          </li>
          <li>
            Opera:{" "}
            <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=http://help.opera.com/Windows/10.00/it/cookies.html">
              http://help.opera.com/Windows/10.00/it/cookies.html
            </a>{" "}
            ;
          </li>
          <li>
            Safari:{" "}
            <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://support.apple.com/kb/PH19255">
              https://support.apple.com/kb/PH19255
            </a>{" "}
            .
          </li>
        </ul>{" "}
        <br />
        <h2>Types of cookies used</h2>
        <p>The types of cookies used on this site are as follows:</p>
        <br />
        <ul>
          <li>
            Session "technical cookies" for authentication (website management,
            restricted areas, acceptance of cookies);
          </li>
          <li>"Third party cookies" for monitoring (Google Analytics);</li>
          {/* <li>"Third party cookies" represented by "Social Plugins";</li> 
          <li>"Third party cookies" for viewing embedded videos (Youtube).</li>  */}
        </ul>{" "}
        <br />
        <h2>Technical cookies</h2>
        <p>
          The use of technical cookies, i.e. necessary for the transmission of
          communications on an electronic communications network, or cookies
          strictly necessary for the supplier to provide the service requested
          by the customer, allows the safe and efficient use of our site. <br />
          Session cookies may be installed in order to allow access and stay in
          the reserved area of &ZeroWidthSpace;&ZeroWidthSpace;the portal as an
          authenticated user.
          <br />
          Technical cookies are essential for the proper functioning of our
          domain and are used to allow users to navigate normally and to take
          advantage of the advanced services available on our website. The
          technical cookies used are divided into session cookies, which are
          stored exclusively for the duration of the navigation until the
          browser is closed, and persistent cookies that are saved in the memory
          of the user's device until their expiration or cancellation by the
          user. same. Our site uses the following technical cookies:
        </p>
        <br />
        <ul>
          <li>
            Technical navigation or session cookies, used to manage normal
            navigation and user authentication;
          </li>
          <li>
            Functional technical cookies, used to memorize personalizations
            chosen by the user, such as, for example, the language;
          </li>
          <li>
            Analytics technical cookies, used to know how users use our website
            so as to be able to evaluate and improve its functioning.
          </li>
        </ul>{" "}
        <br />
        <h2>Third-party cookies for monitoring (Google Analytics)</h2>
        <p>
          Third-party cookies for monitoring can be disabled without any
          consequence on the navigation of the website. <br />
          This site, in relation to these cookies, uses the Google Analytics
          service provided by the company Google, Inc. (hereinafter referred to
          as "Google") to generate statistics on the use of the web portal.
          Google Analytics uses non-third-party cookies, which do not store
          personal data. The place of data processing is in the United States of
          America. <br />
          For the Google Analytics information visit the website{" "}
          <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=http://www.google.com/intl/en/analytics/privacyoverview.html">
            http://www.google.com/intl/en/analytics/privacyoverview.html
          </a>{" "}
          . <br />
          For Google's privacy policies, visit the website{" "}
          <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://www.google.it/intl/it/policies/privacy">
            https://www.google.it/intl/it/policies/privacy
          </a>{" "}
          .
        </p>
        <br />
        {/* <h2>Third party cookies represented by social plugins</h2> 
        <p>The third-party cookies represented by social plugins relate to parts of the visited page generated by social network sites (eg Facebook, Twitter, LinkedIn, Google+) integrated into the host site page. The most common use of social plugins is aimed at sharing content on social networks. <br />The presence of these plugins involves the transmission of cookies to and from all sites managed by third parties. <br />For greater transparency, the web addresses of the various social network information and the methods for managing cookies are shown below.</p>
        <br /> 
        <ul> 
          <li>Facebook (information): <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://www.facebook.com/help/cookies">https://www.facebook.com/help/cookies</a> ;</li> 
          <li>Facebook (configuration): access your account, in the privacy section;</li> 
          <li>Twitter (informativa): <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://support.twitter.com/articles/20170514">https://support.twitter.com/articles/20170514</a>;</li> 
          <li>Twitter (configurazione): <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://www.twitter.com/settings/security">https://www.twitter.com/settings/security</a>;</li> 
          <li>LinkedIn (informativa): <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://www.linkedin.com/legal/cookie-policy">https://www.linkedin.com/legal/cookie-policy</a>;</li> 
          <li>LinkedIn (configurazione): <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://www.linkedin.com/settings">https://www.linkedin.com/settings</a>;</li> 
          <li>Google+ (informativa): <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://www.google.it/intl/it/policies/technologies/cookies">https://www.google.it/intl/it/policies/technologies/cookies</a>;</li> 
          <li>Google+ (configurazione): <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=http://www.google.it/intl/it/policies/technologies/managing">http://www.google.it/intl/it/policies/technologies/managing</a>.</li> 
        </ul> <br />
        <h2>Cookie di terze parti per la visualizzazione di video embedded (Youtube)</h2> 
        <p>I cookie di terze aprti per la visualizzazione di video embedded possono essere disabilitati senza alcuna conseguenza sulla navigazione del sito web.<br /> Il presente sito, relativamente a tali cookie, si avvale del servizio Youtube fornito dalla società Google, Inc. (di seguito denominata “Google”) per la visualizzazione di video. Youtube utilizza cookie non di terze parti, i quali non memorizzano dati personali. Il luogo del trattamento dati è negli Stati Uniti d’America.<br /> Per l’informativa Youtube consultare il sito web <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://www.youtube.com/static?template%3Dprivacy_guidelines">https://www.youtube.com/static?template=privacy_guidelines</a>.<br /> Per le norme sulla privacy di Youtube visitare il sito web <a href="https://translate.google.com/website?sl=auto&amp;tl=en&amp;hl=en-US&amp;client=webapp&amp;u=https://www.google.it/intl/policies/privacy">https://www.google.it/intl/policies/privacy</a>.</p>
        <br />  */}
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
