import React from "react";
import { Helmet } from "react-helmet";
import AboutBg from "../../resources/images/about-bg.jpg";

const Seo = ({ title, desc, bg }) => (
  <Helmet>
    <title>{title} | Internationals Saws</title>
    <meta name="description" content={desc ?? ""}></meta>
    <meta property="og:title" content={title} />
    <meta property="og:description" content={desc} />
    <meta property="og:image" content={bg ?? AboutBg} />
  </Helmet>
);

export default Seo;
