import React from "react";

const BreadCrumb = ({ children }) => (
  <div className="text-white is-p flex flex-wrap py-4 space-x-1 md:mb-6 pr-16">
    {children.map((c, idx, arr) => (
      <React.Fragment key={idx}>
        <div
          className={
            idx + 1 !== arr.length ? "hover:underline underline-offset-2" : ""
          }
        >
          {c}
        </div>
        {idx + 1 !== arr.length && <p>&gt;</p>}
      </React.Fragment>
    ))}
  </div>
);

export default BreadCrumb;
